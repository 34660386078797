import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (function (props) { // eslint-disable-line
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
});

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));

// Dashboard pages

const Perfil = Loadable(lazy(() => import('./components/dashboard/Perfil')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));

// Client pages

const ClientList = Loadable(lazy(() => import('./components/gestion/clientes/ClientList')));
const ClientCreate = Loadable(lazy(() => import('./components/gestion/clientes/ClientCreate')));
const ClientEdit = Loadable(lazy(() => import('./components/gestion/clientes/ClientEdit')));

// Deudor pages

const DeudoresList = Loadable(lazy(() => import('./components/gestion/deudores/DeudoresList')));
const DeudoresCreate = Loadable(lazy(() => import('./components/gestion/deudores/DeudorCreate')));
const DeudoresEdit = Loadable(lazy(() => import('./components/gestion/deudores/DeudorEdit')));

// Riesgo pages

const VaciadosUploader = Loadable(lazy(() => import('./components/gestion/riesgo/VaciadosUploader')));

// Garantias pages

const GarantiasList = Loadable(lazy(() => import('./components/gestion/garantias/GarantiasList')));
const GarantiaCreate = Loadable(lazy(() => import('./components/gestion/garantias/GarantiaCreate')));
const GarantiaEdit = Loadable(lazy(() => import('./components/gestion/garantias/GarantiaEdit')));

// Prospectos pages

const ProspectosList = Loadable(lazy(() => import('./components/gestion/prospectos/ProspectosList')));
const ProspectoEditView = Loadable(lazy(() => import('./components/gestion/prospectos/ProspectoEditView')));

// Operations pages

const OperationsList = Loadable(lazy(() => import('./components/gestion/operaciones/OperationsList')));
const OperationCreate = Loadable(lazy(() => import('./components/gestion/operaciones/OperationCreate')));
const OperationEdit = Loadable(lazy(() => import('./components/gestion/operaciones/OperationEdit')));
const MassOperations = Loadable(lazy(() => import('./components/gestion/operaciones/MassOperations')));

// Gastos pages

const GastosList = Loadable(lazy(() => import('./components/gestion/gastos/GastosList')));

// No Cedidos pages

const NoCedidosList = Loadable(lazy(() => import('./components/gestion/nocedidos/NoCedidosList')));

// Recaud Extra pages

const RecaudExtraList = Loadable(lazy(() => import('./components/gestion/recaudextra/RecaudExtraList')));

// Excedentes pages

const ExcedentesList = Loadable(lazy(() => import('./components/gestion/excedentes/ExcedentesList')));

// Giros pages

const GirosList = Loadable(lazy(() => import('./components/gestion/giros/GirosList')));
const GiroView = Loadable(lazy(() => import('./components/gestion/giros/GiroView')));

// Recaudacion pages

const RecaudacionList = Loadable(lazy(() => import('./components/gestion/recaudacion/RecaudacionList')));

// Cobranza pages

const CobranzaList = Loadable(lazy(() => import('./components/gestion/cobranza/CobranzaList')));
const CobranzaView = Loadable(lazy(() => import('./components/gestion/cobranza/CobranzaView')));

// Reportes pages

const ReportesList = Loadable(lazy(() => import('./components/gestion/reportes/ReportesList')));
const Docsejecutivo = Loadable(lazy(() => import('./components/gestion/reportes/Docsejecutivo')));
const Docscobranza = Loadable(lazy(() => import('./components/gestion/reportes/Docscobranza')));
const Docespecifico = Loadable(lazy(() => import('./components/gestion/reportes/Docespecifico')));
const Docspagados = Loadable(lazy(() => import('./components/gestion/reportes/Docspagados')));
const Docsprorrogados = Loadable(lazy(() => import('./components/gestion/reportes/Docsprorrogados')));
const Docsprotestadoscli = Loadable(lazy(() => import('./components/gestion/reportes/Docsprotestadoscli')));
const Docsprotestadosdeu = Loadable(lazy(() => import('./components/gestion/reportes/Docsprotestadosdeu')));
const Deupublicados = Loadable(lazy(() => import('./components/gestion/reportes/Deupublicados')));
const Historicocltdeudor = Loadable(lazy(() => import('./components/gestion/reportes/Historicocltdeudor')));
const Docspublicados = Loadable(lazy(() => import('./components/gestion/reportes/Docspublicados')));
const Docsmora = Loadable(lazy(() => import('./components/gestion/reportes/Docsmora')));
const Docsproblemas = Loadable(lazy(() => import('./components/gestion/reportes/Docsproblemas')));
const Docscartera = Loadable(lazy(() => import('./components/gestion/reportes/Docscartera')));
const Docscarteraafecha = Loadable(lazy(() => import('./components/gestion/reportes/Docscarteraafecha')));
const Docsdevengados = Loadable(lazy(() => import('./components/gestion/reportes/Docsdevengados')));
const Doccontable = Loadable(lazy(() => import('./components/gestion/reportes/Doccontable')));
const Excedentesafecha = Loadable(lazy(() => import('./components/gestion/reportes/Excedentesafecha')));
const Nocedidosafecha = Loadable(lazy(() => import('./components/gestion/reportes/Nocedidosafecha')));
const Lineasclientes = Loadable(lazy(() => import('./components/gestion/reportes/Lineasclientes')));
const Estadisticocartera = Loadable(lazy(() => import('./components/gestion/reportes/Estadisticocartera')));
const Provisionescartera = Loadable(lazy(() => import('./components/gestion/reportes/Provisionescartera')));
const Informecolocaciones = Loadable(lazy(() => import('./components/gestion/reportes/Informecolocaciones')));
const Gestcobranza = Loadable(lazy(() => import('./components/gestion/reportes/Gestcobranza')));

// Actividades pages

const ActividadesList = Loadable(lazy(() => import('./components/gestion/actividades/ActividadesList')));
const ActividadView = Loadable(lazy(() => import('./components/gestion/actividades/ActividadView')));

// Contabilidad pages

const ContabilidadList = Loadable(lazy(() => import('./components/gestion/contabilidad/ContabilidadList')));
const AsientoView = Loadable(lazy(() => import('./components/gestion/contabilidad/AsientoView')));
const FacturacionList = Loadable(lazy(() => import('./components/gestion/contabilidad/FacturacionList')));

// Configuration pages

const Users = Loadable(lazy(() => import('./components/configuration/users/UserList')));
const UserCreate = Loadable(lazy(() => import('./components/configuration/users/UserCreate')));
const UserEdit = Loadable(lazy(() => import('./components/configuration/users/UserEdit')));
const ExtUserReq = Loadable(lazy(() => import('./components/configuration/users/ExtUserReq')));

// Flujos pages

const FlujosList = Loadable(lazy(() => import('./components/configuration/flujos/FlujosList')));
const FlujoCreate = Loadable(lazy(() => import('./components/configuration/flujos/FlujoCreate')));
const FlujoEdit = Loadable(lazy(() => import('./components/configuration/flujos/FlujoEdit')));

// Mantenedores pages

const ParametrosList = Loadable(lazy(() => import('./components/configuration/mantenedores/ParametrosList')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Other pages

const Home = Loadable(lazy(() => import('./pages/Home')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      }
    ]
  },
  {
    path: 'panel',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Analytics />
      },
      {
        path: 'perfil',
        element: <Perfil />
      }
    ]
  },
  {
    path: 'gestion',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'clientes',
        children: [
          {
            path: '',
            element: <ClientList />
          }
        ]
      },
      {
        path: 'clientes',
        children: [
          {
            path: 'crear-cliente',
            element: <ClientCreate />
          }
        ]
      },
      {
        path: 'clientes',
        children: [
          {
            path: ':clientId',
            element: <ClientEdit />
          }
        ]
      },
      {
        path: 'garantias',
        children: [
          {
            path: '',
            element: <GarantiasList />
          }
        ]
      },
      {
        path: 'garantias',
        children: [
          {
            path: 'crear-garantia',
            element: <GarantiaCreate />
          }
        ]
      },
      {
        path: 'garantias',
        children: [
          {
            path: ':garantiaId',
            element: <GarantiaEdit />
          }
        ]
      },
      {
        path: 'vaciados',
        children: [
          {
            path: '',
            element: <VaciadosUploader />
          }
        ]
      },
      {
        path: 'deudores',
        children: [
          {
            path: '',
            element: <DeudoresList />
          }
        ]
      },
      {
        path: 'deudores',
        children: [
          {
            path: 'crear-deudor',
            element: <DeudoresCreate />
          }
        ]
      },
      {
        path: 'deudores',
        children: [
          {
            path: ':deudorId',
            element: <DeudoresEdit />
          }
        ]
      },
      {
        path: 'prospectos',
        children: [
          {
            path: '',
            element: <ProspectosList />
          }
        ]
      },
      {
        path: 'prospectos',
        children: [
          {
            path: ':clientId/:id',
            element: <ProspectoEditView />
          }
        ]
      },
      {
        path: 'operaciones',
        children: [
          {
            path: '',
            element: <OperationsList />
          }
        ]
      },
      {
        path: 'operaciones',
        children: [
          {
            path: 'crear-operacion',
            element: <OperationCreate />
          }
        ]
      },
      {
        path: 'operaciones',
        children: [
          {
            path: 'mass-operations',
            element: <MassOperations />
          }
        ]
      },
      {
        path: 'operaciones',
        children: [
          {
            path: ':clientId/:operationId',
            element: <OperationEdit />
          }
        ]
      },
      {
        path: 'giros',
        children: [
          {
            path: '',
            element: <GirosList />
          }
        ]
      },
      {
        path: 'giros',
        children: [
          {
            path: ':giroId',
            element: <GiroView />
          }
        ]
      },
      {
        path: 'recaudacion',
        children: [
          {
            path: '',
            element: <RecaudacionList />
          }
        ]
      },
      {
        path: 'gastos',
        children: [
          {
            path: '',
            element: <GastosList />
          }
        ]
      },
      {
        path: 'nocedidos',
        children: [
          {
            path: '',
            element: <NoCedidosList />
          }
        ]
      },
      {
        path: 'recaudextra',
        children: [
          {
            path: '',
            element: <RecaudExtraList />
          }
        ]
      },
      {
        path: 'excedentes',
        children: [
          {
            path: '',
            element: <ExcedentesList />
          }
        ]
      },
      {
        path: 'actividades',
        children: [
          {
            path: '',
            element: <ActividadesList />
          }
        ]
      },
      {
        path: 'actividades',
        children: [
          {
            path: ':actividadId',
            element: <ActividadView />
          }
        ]
      },
      {
        path: 'cobranza',
        children: [
          {
            path: '',
            element: <CobranzaList />
          }
        ]
      },
      {
        path: 'cobranza',
        children: [
          {
            path: ':clientId/:operationId/:documentId/:cobranzaId',
            element: <CobranzaView />
          }
        ]
      },
      {
        path: 'contabilidad',
        children: [
          {
            path: '',
            element: <ContabilidadList />
          }
        ]
      },
      {
        path: 'contabilidad',
        children: [
          {
            path: ':asientoId',
            element: <AsientoView />
          }
        ]
      },
      {
        path: 'facturacion',
        children: [
          {
            path: '',
            element: <FacturacionList />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: '',
            element: <ReportesList />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/deupublicados',
            element: <Deupublicados />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsejecutivo',
            element: <Docsejecutivo />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docscobranza',
            element: <Docscobranza />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docespecifico',
            element: <Docespecifico />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docspagados',
            element: <Docspagados />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsprorrogados',
            element: <Docsprorrogados />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsprotestadoscli',
            element: <Docsprotestadoscli />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsprotestadosdeu',
            element: <Docsprotestadosdeu />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/historicocltdeudor',
            element: <Historicocltdeudor />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docspublicados',
            element: <Docspublicados />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsmora',
            element: <Docsmora />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsproblemas',
            element: <Docsproblemas />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docscartera',
            element: <Docscartera />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docscarteraafecha',
            element: <Docscarteraafecha />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/doccontable',
            element: <Doccontable />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/excedentesafecha',
            element: <Excedentesafecha />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/nocedidosafecha',
            element: <Nocedidosafecha />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/lineasclientes',
            element: <Lineasclientes />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/provisionescartera',
            element: <Provisionescartera />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/estadisticocartera',
            element: <Estadisticocartera />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/docsdevengados',
            element: <Docsdevengados />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/informecolocaciones',
            element: <Informecolocaciones />
          }
        ]
      },
      {
        path: 'reportes',
        children: [
          {
            path: 'codigo/gestcobranza',
            element: <Gestcobranza />
          }
        ]
      },
    ]
  },
  {
    path: 'configuracion',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'usuarios',
        children: [
          {
            path: '',
            element: <Users />
          }
        ]
      },
      {
        path: 'usuarios',
        children: [
          {
            path: 'crear-usuario',
            element: <UserCreate />
          }
        ]
      },
      {
        path: 'usuarios',
        children: [
          {
            path: ':userId',
            element: <UserEdit />
          }
        ]
      },
      {
        path: 'extuserreq',
        children: [
          {
            path: ':reqId',
            element: <ExtUserReq />
          }
        ]
      },
      {
        path: 'mantenedores',
        children: [
          {
            path: '',
            element: <ParametrosList />
          }
        ]
      },
      {
        path: 'flujos',
        children: [
          {
            path: '',
            element: <FlujosList />
          }
        ]
      },
      {
        path: 'flujos',
        children: [
          {
            path: 'crear-flujo',
            element: <FlujoCreate />
          }
        ]
      },
      {
        path: 'flujos',
        children: [
          {
            path: ':flujoId',
            element: <FlujoEdit />
          }
        ]
      },
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
